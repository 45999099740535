export const faqHandler = {
    init: function () {
        this.informationsAccordeons();
    },

    informationsAccordeons: function () {

        let $panels = document.querySelectorAll('.panel');
        let $panelsToggle = document.querySelectorAll('.panel--toggle');

        if ($panelsToggle) {
            Array.prototype.forEach.call($panelsToggle, function ($toggle) {
                $toggle.addEventListener('click', () => {
                    let $panel = $toggle.closest('.panel');
                    let $content = $panel.querySelector('.panel--content');
                    let height = $panel.querySelector('.panel--content-wrap').clientHeight;

                    if ($panel.classList.contains('opened')) {
                        resetPanels();
                    } else {
                        $panel.classList.add('opened');
                        $content.style.maxHeight = height + 'px';
                        resetPanels($panel);
                        // setTimeout( () => {
                        // 	scrollToPanel($panel)
                        // },600);
                    }
                });
            });

            function resetPanels($panel) {
                let $panelsFiltered = Array.from($panels).filter(node => node != $panel);
                Array.prototype.forEach.call($panelsFiltered, function ($panel) {
                    $panel.classList.remove('opened');
                    $panel.querySelector('.panel--content').style.maxHeight = 0;
                });
            }
            // function scrollToPanel($panel) {
            //     let offset = $panel.getBoundingClientRect().top + window.scrollY;
            //     window.scrollTo({
            //         'top': offset,
            //         behavior: 'smooth'
            //     });
            // }
        }
    },
}