let $galleries = document.querySelectorAll('[id^="gallery-"]');

export const galleryHandler = {
    init: function () {


        if (!$galleries) return;

        this.setGalleries($galleries);

        document.addEventListener("keydown", ({ key }) => {
            if (key === "Escape") {
                galleryHandler.closeGalleries();
            }
        });


    },

    setGalleries: ($galleries) => {

        galleryHandler.setGalleryThumbs();

        Array.prototype.forEach.call($galleries, ($gallery) => {

            let $btnCloseGallery = $gallery.querySelector('.btn-close-gallery');
            $btnCloseGallery.addEventListener('click', () => {
                $gallery.querySelector('[id^="fullscreen-gallery-"]').classList.add('hidden');
            });

        });


    },
    setGalleryThumbs: () => {

        let $thumbs = document.querySelectorAll('[data-thumb-id]');
        if (!$thumbs) return;

        Array.prototype.forEach.call($thumbs, ($thumb) => {
            $thumb.addEventListener('click', () => {
                let galleryId = $thumb.closest('[id^="gallery-"]');
                let thumbId = parseInt($thumb.dataset.thumbId);
                // console.log(thumbID);
                // open gallery to thumb X
                galleryHandler.openGallery(galleryId, thumbId);
            });
        })
    },


    openGallery: (galleryId, thumbId) => {
        // let $galleryFullcren = document.querySelector('')
        let $sliderFullscreen = galleryId.querySelector('[id^="fullscreen-gallery-"]');
        $sliderFullscreen.classList.remove('hidden');
        // console.log(galleryId, thumbId, $sliderFullscreen);

        let swiper = galleryId.querySelector('.gslider-fullscreen').swiper;
        // swiper.update();
        swiper.slideTo(thumbId - 1);

    },

    closeGalleries: () => {

        let $openedGalleries = Array.from($galleries).filter(node => !node.querySelector('[id^="fullscreen-gallery-"]').classList.contains('hidden'));

        Array.prototype.forEach.call($openedGalleries, ($openedGallery) => {
            $openedGallery.querySelector('[id^="fullscreen-gallery-"]').classList.add('hidden');
        });
    },

    handlePrivateContent: (swiper) => {
        // let swiper = galleryId.querySelector('.gslider-fullscreen').swiper;
        // // swiper.slideTo(thumbId - 1);
        // console.log(swiper);
        // swiper.on('slideChange', () => {
        //     console.log(swiper.activeIndex)
        // });
        // if (!swiper.el.querySelector('.private')) return;

        let realIndex = swiper.realIndex;
        let actions = swiper.el.closest('[id^=fullscreen-]').querySelector('.actions');
        let slideIsPrivate = swiper.slides[realIndex].querySelector('.private');
        // console.log(swiper, swiper.el);
        actions.innerHTML = "";

        if (!slideIsPrivate) {
            let btnDownload = document.createElement('a');
            btnDownload.innerHTML = "<span class=\"button--label\">Télécharger</span>";
            btnDownload.innerHTML += "<svg class=\"icon icon-download\" aria-hidden=\"true\" focusable=\"false\"><use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-download\"></use></svg>";
            btnDownload.setAttribute('target', '_blank');
            btnDownload.setAttribute('rel', 'noopener');
            btnDownload.setAttribute('href', swiper.slides[realIndex].querySelector('img').dataset.downloadUrl);
            btnDownload.classList.add('btn-primary');
            actions.appendChild(btnDownload);
        } else {
            actions.innerHTML = "";
        }
        // console.log(slideIsPrivate, actions)
    }
}