export const formsHandler = {
    init: function () {
        formsHandler.checkbox();
        formsHandler.radio();
        formsHandler.select();
        formsHandler.date();
        formsHandler.file();

        formsHandler.searchBox();
    },

    checkbox: function () {

        let $checkboxes = document.querySelectorAll('.input--field.checkbox input[type="checkbox"], .input--field.gdpr input[type="checkbox"]');
        if ($checkboxes) {
            Array.prototype.forEach.call($checkboxes, function ($checkbox, i) {
                // Set once
                setCheckbox($checkbox);
                // Toggle on change
                $checkbox.addEventListener('change', function () {
                    setCheckbox($checkbox);
                });
            });
        }

        function setCheckbox($checkbox) {
            if ($checkbox.checked === true) {
                $checkbox.closest('.input--field.checkbox, .input--field.gdpr').classList.add('checked');
                $checkbox.closest('.input--field.checkbox, .input--field.gdpr').classList.remove('indeterminate');
            } else if ($checkbox.indeterminate === true) {
                $checkbox.closest('.input--field.checkbox, .input--field.gdpr').classList.add('indeterminate');
            } else {
                $checkbox.closest('.input--field.checkbox, .input--field.gdpr').classList.remove('checked', 'indeterminate');
            }
        }

    },

    radio: function () {

        let $radios = document.querySelectorAll('.input--field.radio input[type="radio"]');
        if ($radios) {
            Array.prototype.forEach.call($radios, function ($radio, i) {
                // Set once
                setRadio($radio);
                // Toggle on change
                $radio.addEventListener('change', function () {
                    let $group = $radio.closest('.radiogroup');
                    if ($group) {
                        resetRadioGroup($group);
                    } else {
                        setRadio($radio);
                    }
                });
            });
        }

        function setRadio($radio) {
            if ($radio.checked === true) {
                $radio.closest('.input--field.radio').classList.add('checked');
            } else {
                $radio.closest('.input--field.radio').classList.remove('checked');
            }
        }

        function resetRadioGroup($group) {
            let $radios = $group.querySelectorAll('.input--field.radio input[type="radio"]');
            if ($radios) {
                Array.prototype.forEach.call($radios, function ($radio, i) {
                    setRadio($radio)
                });
            }
        }

    },

    select: function () {
        let $selects = document.querySelectorAll('.input--field.select .select-wrap select');
        if ($selects) {
            Array.prototype.forEach.call($selects, function ($select, i) {

                // Pagination - Handle Selects with data-url params in options
                $select.addEventListener('change', function () {
                    let value = $select.value;
                    let $option = $select.querySelector('option[value="' + value + '"]');
                    let gotoUrl = (typeof $option.dataset.url !== 'undefined') ? $option.dataset.url : '';
                    // console.log($select.value, $option, $option.dataset.url)
                    if (gotoUrl) {
                        window.location = gotoUrl;
                    }
                });

            });
        }
    },

    date: function () {

        let $dates = document.querySelectorAll('.input--field.date input');
        if ($dates) {
            Array.prototype.forEach.call($dates, function ($date, i) {

                $date.addEventListener('blur', function () {
                    setDatePlaceholder($date);
                });

                $date.addEventListener('focusin', function () {
                    setDatePlaceholder($date);
                });

            });
        }

        function setDatePlaceholder($fieldDate) {
            let $placeholder = $fieldDate.parentNode.querySelector('.date-placeholder');
            if ($fieldDate.value && $fieldDate.value.split('-').length > 0) {
                let date = $fieldDate.value.split('-');
                $placeholder.innerHTML = date[2] + '/' + date[1] + '/' + date[0];
                $placeholder.classList.add('has-value');
            } else {
                $placeholder.innerHTML = $placeholder.dataset.label;
                $placeholder.classList.remove('has-value');
            }
        }

    },

    file: function () {

        let $files = document.querySelectorAll('.input--field.file input');
        if ($files) {
            Array.prototype.forEach.call($files, function ($file, i) {

                // $file.addEventListener('blur', function () {
                //     setFilePlaceholder($file);
                // });

                $file.addEventListener('change', function () {
                    setFilePlaceholder($file);
                });

            });
        }

        function setFilePlaceholder($fieldFile) {
            let $placeholder = $fieldFile.parentNode.querySelector('.file-placeholder');
            if ($fieldFile.value) {
                $placeholder.innerHTML = $fieldFile.value.match(/[^\\/]*$/)[0];
                $placeholder.classList.add('has-value');
            } else {
                $placeholder.innerHTML = $placeholder.dataset.label;
                $placeholder.classList.remove('has-value');
            }
        }

    },

    searchBox: () => {
        let $input = document.querySelector('#searchbox[data-livesearch]');

        if (!$input) return;

        let cards = document.querySelectorAll($input.dataset.livesearchItems);
        // let cardsDataset = ;
        let noresults = document.querySelector('#searchbox_noresults');

        function liveSearch() {
            // Locate the card elements
            // Locate the search input
            let search_query = $input.value;
            // Loop through the cards
            let countHidden = 0;
            for (var i = 0; i < cards.length; i++) {
                // If the text is within the card...
                if (cards[i].querySelector($input.dataset.livesearchDataset).innerText.toLowerCase()
                    // ...and the text matches the search query...
                    .includes(search_query.toLowerCase())) {
                    // ...remove the `.is-hidden` class.
                    cards[i].closest('a').classList.remove("hidden");
                } else {
                    // Otherwise, add the class.
                    cards[i].closest('a').classList.add("hidden");
                    countHidden++;
                }

                countHidden === cards.length ? noresults.classList.remove('hidden') : noresults.classList.add('hidden')

            }
        }

        let typingTimer;
        let typeInterval = 500; // Half a second
        // let searchInput = document.getElementById('searchbox');

        $input.addEventListener('keyup', () => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(liveSearch, typeInterval);
        });


        $input.addEventListener('input', liveSearch);

        function showNoResults() {

        }

    }
}