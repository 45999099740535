import * as _utils from './_utils';
import { mobileHandler } from './modules/mobile';
import { uiHandler } from './modules/ui';
import { formsHandler } from './modules/forms';
import { sharersHandler } from './modules/sharers';
import { galleryHandler } from './modules/gallery'
import { faqHandler } from './modules/faq'

document.addEventListener('DOMContentLoaded', function (event) {
    // Exclude some modules from WP Admin
    console.log(_utils.is_WP_Admin(), document.body.classList.contains('wp-admin'));
    if (!_utils.is_WP_Admin()) {
        mobileHandler.init();
    }
    uiHandler.init();
    formsHandler.init();
    sharersHandler.init();
    galleryHandler.init()
    faqHandler.init()
})