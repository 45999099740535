const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const options = {
    reserveScrollBarGap: true,
};

const $_body = document.body
const $_header = document.querySelector('#header-main');
const $_btnMenu = $_header && $_header.querySelector('#btn-toggle-menu') ? $_header.querySelector('#btn-toggle-menu') : '';
const $_parentMenus = $_header && $_header.querySelectorAll('li.has-submenu, li.has-megamenu') ? $_header.querySelectorAll('li.has-submenu, li.has-megamenu') : '';
const $_menuExpand = $_header && $_header.querySelector('#menu-aside') ? $_header.querySelector('#menu-aside') : '';
const $_menuContent = $_header && $_header.querySelector('#menu-aside__content') ? $_header.querySelector('#menu-aside__content') : '';
const $_btnSearch = $_header && $_header.querySelectorAll('#btn-toggle-search') ? $_header.querySelector('#btn-toggle-search') : '';

export function mobileBreakpoint() {
    return 768;
}

export function headerBreakpoint() {
    return 1024;
}

export const mobileHandler = {
    init: () => {

        mobileHandler.isMobile();
        mobileHandler.setRealVH();
        mobileHandler.mobileNav();
        // mobileHandler.mobileSearch();
        // mobileHandler.ux();

        window.addEventListener('resize', () => {
            mobileHandler.isMobile();
            mobileHandler.setRealVH();
            // clearTimeout(mobileNav);
            // mobileNav = setTimeout(mobileHandler.mobileNav(), 500);
            // mobileHandler.ux();
        });

        // mobileHandler.debug();

    },

    isMobile: () => {
        let isMobile = getViewportWidth() < mobileBreakpoint();
        if (isMobile) {
            document.body.classList.add('mobile');
        } else {
            document.body.classList.remove('mobile');
        }
    },

    setRealVH: () => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    mobileNav: () => {

        let isMobile = getViewportWidth() < headerBreakpoint();
        // let $header = document.querySelector('#header-main');
        // let $parentsMenu = $header.querySelectorAll('.has-submenu')
        // let $burger;

        if ($_header && $_btnMenu) {
            // $burger = $_header.querySelector('#btn-toggle-menu');
            let $menuWrap = document.querySelector('#menu');



            document.addEventListener('click', (e) => {
                if (e.target === $_btnMenu || $_btnMenu.contains(e.target) || e.target === $_btnSearch || $_btnSearch.contains(e.target) ) {
                    $_btnMenu.classList.toggle('opened');
                    $_header.classList.toggle('menu-is-opened');

                    if ($_btnMenu.classList.contains('opened')) {
                        disableBodyScroll($_menuContent, options);
                    } else {
                        enableBodyScroll($_menuContent);
                    }
                }
            })

            document.addEventListener('click', (e) => {
                if (e.target == $_menuExpand && !$_menuContent.contains(e.target)) {
                    mobileHandler.closeMenuAside();
                }
            })

            if ($_header.querySelector('.btn-close-menu')) {
                $closeMenu = $_header.querySelector('.btn-close-menu');

                $closeMenu.addEventListener('click', (e) => {
                    mobileHandler.closeMenuAside();
                });
            }

            document.addEventListener("keydown", ({ key }) => {
                if (key === "Escape") {
                    mobileHandler.closeMenuAside();
                }
            });

        }


        /** MANAGE SUBMENU **/
        // let $anchorSubmenus = $_parentMenus.querySelectorAll(':scope > a');
        if ($_header && $_btnMenu) {

            Array.prototype.forEach.call($_parentMenus, ($_parentMenu) => {
                // if (isMobile) {
                $_parentMenu.querySelector(':scope > a').addEventListener('click', mobileHandler.toggleSubmenu);
                // } else {
                // $anchorSubmenu.removeEventListener('click', mobileHandler.toggleSubmenu);
                // }
            });

            // Auto close submenus when clicking outside
            // let $navContent = document.querySelector('#menu li.has-submenu, #menu li.has-megamenu');
            // if ($navContent) {
            // document.addEventListener('click', (el) => {
            //     if (el.target !== $_parentMenus && !$_parentMenus.contains(el.target)) {
            //         let target = el.target.closest('a');
            //         mobileHandler.closeAllSubmenus(target);
            //     }
            // });
            // }
        }

    },

    // mobileSearch: () => {
    //     let $menuWrap = document.querySelector('#menu');

    //     document.addEventListener('click', (e) => {
    //         if (e.target === $_btnSearch || $_btnSearch.contains(e.target)) {
    //             $_btnSearch.classList.toggle('opened');
    //             $_header.classList.toggle('search-is-opened');
    //             if ($_btnSearch.classList.contains('opened')) {
    //                 disableBodyScroll($menuWrap);
    //             } else {
    //                 enableBodyScroll($menuWrap);
    //             }
    //         }
    //     })

    //     if ($_header.querySelector('.btn-close-search')) {
    //         $closeMenu = $_header.querySelector('.btn-close-search');
    //         $closeMenu.addEventListener('click', (e) => {
    //             mobileHandler.closeSearchAside();
    //         });
    //     }

    //     document.addEventListener("keydown", ({ key }) => {
    //         if (key === "Escape") {
    //             mobileHandler.closeSearchAside();
    //         }
    //     });
    // },

    openMenuAside: () => {

    },

    closeMenuAside: () => {
        // enableBodyScroll($menuWrap); // TODO
        mobileHandler.closeAllSubmenus();
        $_btnMenu.classList.remove('opened');
        $_header.classList.remove('menu-is-opened');
        enableBodyScroll($_menuContent);
    },

    // closeSearchAside: () => {
    //     mobileHandler.closeAllSubmenus();
    //     $_btnSearch.classList.remove('opened');
    //     $_header.classList.remove('search-is-opened');
    // },

    toggleSubmenu: (el) => {
        el.preventDefault();
        let menuAnchor;

        // open MenuAside with nav
        if (el.target.hasAttribute('data-menu-anchor')) {
            $_btnMenu.click();
            menuAnchor = el.target.dataset.menuAnchor;
        } else {
            menuAnchor = el.target.dataset.menuId;
        }

        let target = el.target.closest('a');
        // let menuAside = document.querySelector('#menu-aside');




        let menuTarget = $_menuExpand.querySelector('[data-menu-id="' + menuAnchor + '"]');
        // console.log(target, menuAnchor, menuTarget);
        // if (target.classList.contains('submenu-parent') && getViewportWidth() < headerBreakpoint()) {

        // Close others items, then expand target
        mobileHandler.closeAllSubmenus(target);
        menuTarget.classList.add('expanded');



        // let $parentToToggle = target.closest('li.has-submenu');
        let $parentToToggle = menuTarget.closest('li.has-submenu'); // Alias
        let $parentIsOpened = $parentToToggle.classList.contains('expanded') ? true : false;

        console.log($parentToToggle, $parentIsOpened);

        if (!$parentIsOpened || ($parentIsOpened && target.getAttribute('href') === '#')) {
            $parentToToggle.classList.toggle('expanded');
        } else {
            window.location = target.href;
        }

        // }
    },

    closeAllSubmenus: (target) => {

        let $_parentMenusFiltered = $_parentMenus; // Default case when 'target' is empty

        if (target) {
            $_parentMenusFiltered = Array.from($_parentMenus).filter(node => node !== target.closest('li.has-submenu, li.has-megamenu'));
        }

        Array.prototype.forEach.call($_parentMenusFiltered, ($_parentMenu) => {
            $_parentMenu.classList.remove('expanded');
        });
    },

    ux: () => {

    },

    debug: () => {
        let vw = getViewportWidth();
        console.log('vw: ' + vw);
        window.addEventListener('resize', () => {
            vw = getViewportWidth();
            console.log('vw: ' + vw);
        });
    }
}

export function getViewportWidth() {
    let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    return viewportWidth;
}
