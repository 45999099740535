export function getMobileBreakpoint() {
    return 768;
}

export function getViewportWidth() {
    let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    return viewportWidth;
}

export function getViewportHeight() {
    let viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    return viewportHeight;
}

export function wrap(el) {
    const wrappingElement = document.createElement('div');
    el.replaceWith(wrappingElement);
    wrappingElement.appendChild(el);
}

export const is_WP_Admin = () => {
    return document.body.classList.contains('wp-admin');
}